<template>
  <div>
    <b-row class="match-height">
      <b-col lg="12">
        <card-analytic-goal-overview :card-data="clientTypesData" />
      </b-col>
    </b-row>
    <statistic-card-horizontal
      icon="UsersIcon"
      color="warning"
      :statistic="totalClients"
      statistic-title="إجمالي العملاء"
      @refetch-total="updateTotalClients"
    />
    <client-list-add-new
      :is-add-new-client-sidebar-active.sync="isAddNewClientSidebarActive"
      :type-options="typeOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>مدخلات</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="بحث..."
              />
              <b-button
                variant="primary"
                :to="{ name: 'clients-add' }"
              >
                <span class="text-nowrap">إضافة {{ $t("client") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refClientListTable"
        class="position-relative"
        :items="fetchClients"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="لم يتم العثور على سجلات مطابقة"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: type text -->
        <template #cell(reference)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <!-- Column: type text -->
        <template #cell(name)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type text -->
        <template #cell(type)="data">
          <span class="text-nowrap">
            {{ $t(data.item.type) }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type text -->
        <template #cell(status)="data">
          <span class="text-nowrap">
            {{ $t(data.value) }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'clients-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >عرض من {{ dataMeta.from }} إلى {{ dataMeta.to }} من
              {{ dataMeta.of }} مدخلات</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalClient"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import CardAnalyticGoalOverview from '@core/components/cards/CardAnalyticGoalOverview.vue';
import ApexDonutChart from '@core/components/charts/apex-chart/ApexDonutChart.vue';
import ClientListFilters from './ClientListFilters.vue';
import useClientList from './useClientList';
import clientStore from '../clientStore';
import ClientListAddNew from './ClientListAddNew.vue';

export default {
  components: {
    CardAnalyticGoalOverview,
    ApexDonutChart,
    ClientListFilters,
    ClientListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    StatisticCardHorizontal,
    vSelect,
  },
  data() {
    return {
      clientStatusesData: {
        title: 'حالة العملاء',
        chart: 'radialBar',
        series: [85],
        data: [
          {
            label: 'نشط',
            value: 85,
          },
          {
            label: 'غير نشط',
            value: 15,
          },
        ],
      },
      clientsTotal: {
        title: 'إجمالي العملاء',
        chart: 'radialBar',
        series: [19],
        data: [
          {
            label: 'إجمالي العملاء',
            value: 19,
          },
        ],
      },
    };
  },
  methods: {
    deleteClient(client) {
      store
        .dispatch('client/deleteClient', { id: client.id })
        .then((response) => {
          this.$emit('refetch-data');
          this.$emit('refetch-total'); // Emit the event after successful deletion
          this.refetchData();
          this.updateTotalClients();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            clientData.value = undefined;
          }
        });
    },
    updateTotalClients() {
      store.dispatch('client/stats').then((res) => {
        this.totalClients = res.data.totalClients;
      });
    },

    confirmDelete(client) {
      this.$bvModal
        .msgBoxConfirm(`يرجى تأكيد أنك تريد حذف العنصر  ${client.id}.`, {
          title: 'يرجى التأكيد',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'نعم',
          cancelTitle: 'لا',
          cancelVariant: 'outline-warning',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteClient(client);
          }
        });
    },
  },
  setup() {
    const CLIENT_STORE_MODULE_NAME = 'client';

    // Register client
    if (!store.hasModule(CLIENT_STORE_MODULE_NAME)) store.registerModule(CLIENT_STORE_MODULE_NAME, clientStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_STORE_MODULE_NAME);
    });
    const totalClients = ref(0);
    const clientTypesData = ref(null);
    store.dispatch('client/stats').then((res) => {
      const labels = res.data.clientsByType.map((item) => item.label);
      const chartOptions = {
        title: 'أنواع العملاء',
        chart: 'donut',
        series: res.data.clientsByType.map((item) => item.count),
        chartOptions: {
          labels,
        },
        data: res.data.clientsByType.map((item) => ({
          label: item.label,
          value: item.count,
        })),
      };

      totalClients.value = res.data.totalClients;
      clientTypesData.value = chartOptions;
    });
    const isAddNewClientSidebarActive = ref(false);
    const typeOptions = ref([]);
    store
      .dispatch('client/fetchClientOption', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { clientTypes } = response.data;
        typeOptions.value = clientTypes;
      });

    const {
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClient,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,

      // UI
      typeFilter,
    } = useClientList();

    return {
      // Sidebar
      isAddNewClientSidebarActive,
      clientTypesData,
      totalClients,
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClient,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      typeOptions,
      typeFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
