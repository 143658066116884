<template>
  <b-card no-body>
    <b-card-header>
      <h4 class="mb-0">
        {{ cardData.title }}
      </h4>
      <!-- <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text> -->
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      :key="cardData.title"
      :type="cardData.chart"
      height="245"
      :options="cardData.chartOptions"
      :series="cardData.series"
    />
    <b-row
      v-if="!cardData.hideLegend"
      class="text-center mx-0"
    >
      <b-col
        v-for="(item, index) in cardData.data"
        :key="index"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ item.label }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-50">
          {{ item.value }}
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import { $themeColors } from '@themeConfig';

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      goal_overview: {},
      donutChart: {
        series: [85, 16, 50, 50],
        chartOptions: {
          labels: this.cardData.labels,
          legend: {
            show: false,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Cairo, Helvetica, Arial, serif',
          },
          colors: [
            '#ffe700',
            '#00d4bd',
            '#826bf8',
            '#2b9bf4',
            '#FFA1A1',
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}`;
            },
          },
          plotOptions: {
            pie: {
              size: 200,
              donut: {
                labels: {
                  show: false,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Cairo, Helvetica, Arial, serif',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Cairo, Helvetica, Arial, serif',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      },
      goalOverviewRadialBar: {
        series: [83],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#5e5873',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
          labels: this.cardData.labels,
        },
      },
    };
  },
  created() {
    console.log('cardData', this.cardData);
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Cairo:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap');
.apexcharts-legend-text{
  font-family: 'Cairo', Helvetica, Arial, serif  !important;
  font-size: 12px  !important;
}
</style>
