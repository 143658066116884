import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/client', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/client/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClientStats(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/client/${id}/stats`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateClient(ctx, { clientData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/client/${id}`, clientData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateAddress(ctx, { addressData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/client/${id}/address`, addressData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateContact(ctx, { contactData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/client/${id}/contact`, { contacts: contactData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/client/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClient(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/client', clientData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClientOption(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/client/options')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    stats(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/client/stats')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCities(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/city/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDistricts(ctx, { id }) {
      console.log(id);
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/district/city/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateLogoImage(ctx, { id, logoData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/client-logo/${id}`, logoData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
